html, body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow: auto;
}

// color theme
$beige: #F0F1EE;
$dark-gray: #333332;
$blue: #1352A2;
$yellow: #FFD464;
$orange: #FB6964;

// misc colors
$deep-blue: #212029;
$gray: #888888;
$light-gray: #cccccc;

$retro-fonts: "Roboto Mono", Monaco, monospace;

a {
  padding: 0 4px;
  &:link, &:visited {
    color: $orange;
    text-decoration: none;
  }
  &:hover, &:active {
    color: $beige;;
    background-color: $orange;
    text-decoration: none;
  }
}

a.img-link { background-color: transparent; }

.container {
  width: 96%;
  padding: 2%;
  float: left;
  clear: both;
}

// don't let images overflow their containers
img { max-width: 100%; }

table, th, td {
  border: 1px solid black;
  padding: 1em;
  text-align: center;
}

/*************************************************************************/

#content-container {
  @extend .container;
  background-color: $beige;
}

main {
  font-family: $retro-fonts;
  font-size: 17px;
  line-height: 28px;
  min-width: 300px;
  width: 75%;
  margin: 0 auto;
}

// For some reason, only on Chrome for Android, and not on anything else (I also
// tried Firefox, Chrome, and Firefox for Android), the code blocks appear to be
// in serif font which looks horrible because it isn't monospaced. I think the
// issue might be that Chrome for Android is lacking a font that satisfies
// "monospace"? Roboto Mono is explicitly loaded, though (and it's the first
// font specified in $retro-fonts), so we can use that just like we're using it
// in the "main" rule above.
pre, code {
  font-family: $retro-fonts;
}

hr {
  margin: 35px 20px;
}

article {
  h1, h2, h3 {
    margin: 40px 0;
    line-height: 1.2em
  }
  p {
    margin: 1.5em 0;
  }
}

.page-header {
  padding: 0 4px 0 4px;
  color: $beige;
  background-color: $blue;
  font-family: $retro-fonts;
  font-size: 25px;
  line-height: 35px;
  font-weight: normal;
}

.site-title {
  @extend .page-header;
  a {
    background-color: transparent;
    text-decoration: none;
    &:link, &:visited {
      color: $beige;
    }
    &:hover, &:active {
      color: $beige;
    }
  }
}

.classification {
  display: block;
  h4 {
    display: inline;
  }
  ul {
    display: inline;
    padding: 0;

    li {
      display: inline;
      list-style-type: none;
      &:after {
        content: "/ ";
      }
      &:last-child:after {
        content: none;
      }
    }
  }
}

:not(pre) > code {
  background: $light-gray;
  padding: 3.5px;
}

pre {
  overflow: auto;
  word-wrap: normal;
  white-space: pre;
  padding: 1px 5px;
}

blockquote {
  border: 2px solid $gray;
  padding-left: 1em;
}

/*************************************************************************/

#header-container {
  @extend .container;
  background: $beige;
}

img#portrait {
  width: 100px;
  height: 100px;
  border: 0px;
  border-radius: 10px;
}

#project-links {
  margin-top: 1em;
  margin-bottom: 1em;
  p {
    margin-bottom: -1em;
    font-family: $retro-fonts;
    font-weight: bold;
    font-size: 17px;
  }
}

/*************** index/archive/category pages *****************/

.month-header {
  @extend .page-header;
  font-size: 20px;
}

#post-title {
  font-family: $retro-fonts;
  font-size: 25px;
  line-height: 50px;
}

#teaser {
  width: 90%;
  margin: 0 auto;
}

ul.archive-posts, ul.category-posts, ul.tag-posts {
  padding: 0;
  margin: 10px 0 0 0;
  list-style-type: none;
}

/**************************************************************/

.light-box {
  display: inline-block;
  padding: 5px;
  background-color: $beige;
  box-shadow: 10px 10px 5px $gray;
}

#footer-container {
  @extend .container;
  background-color: $deep-blue;
  padding-top: 20px;
  padding-bottom: 40px;

  font-family: $retro-fonts;
  font-weight: bold;
  font-size: 14px;
}

footer {
  position: relative;
  width: 525px;
  margin: 0 auto;
}

.pagination {
  @extend .light-box;
  display: block;
  width: 260px;
  margin: 0 auto;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
}

#latest {
  @extend .light-box;
  display: block;
  width: 450px;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
}

#cats-and-tags-and-feeds {
  position: relative;
  display: block;
  width: 525px;
  height: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
}

#cats {
  @extend .light-box;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: top;
}

#tags {
  @extend .light-box;
  margin-right: 10px;
}

#feeds {
  display: inline-block;
  vertical-align: top;
  margin-top: -18px;
}

#rss-feed {
  @extend .light-box;
  display: block;
  padding: 10px;
}

#atom-feed {
  @extend .light-box;
  display: block;
  padding: 10px;
}

#copyright {
  @extend .light-box;
  display: block;
  width: 200px;
  margin: 0 auto;
  text-align: center;
}
